import MDinput from '@/components/MDinput';

export function getFormDefinition(vue) {
  return {
    postcode: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'common.postalCode',
        maxlength: 64,
        required: true,
        disabled: true
      }
    },
    areaName: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 32
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
